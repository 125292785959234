import React from 'react'
import { useTranslation } from 'react-i18next'

import LayoutContent from '../components/layout/layoutContent'

const NotFoundPage = props => {
    const { t } = useTranslation()

    return (
        <LayoutContent {...props} hasVerticalGutters>
            <h1>{t('Not found')}</h1>
            <p>{t('The page you’re looking for doesn’t exist.')}</p>
        </LayoutContent>
    )
}

export default NotFoundPage
